import React from 'react';
import { Row, Col, Typography } from 'antd';
import Layout from '../components/Layout/layout';
import SEO from '../components/seo';
import ContactUsHero from '../components/Hero/contact-us-hero';
import { contactUsHero } from '../data/contact-us-data';
import CtaSecondary from '../components/Buttons/secondary-cta';
import CtaPrimary from '../components/Buttons/primary-cta';
import { viewports, colors } from '../style-vars';
import heroBg from '../images/hero-images/contact-us-hero.png';
import knowledge from '../images/subvertl-images/knowledge.png';
import team from '../images/subvertl-images/team.png';
import check from '../images/icons/big-green-checkmark.png';

const { Title } = Typography;
const { smOrSmaller, mdOrSmaller } = viewports;

export default function ContactThanks() {
  return (
    <Layout>
      <SEO
        title="SpotOn | You have submitted your demo request"
        image={`https://spoton.com/${heroBg}`}
      />
      <ContactUsHero sectionData={contactUsHero} heroBg={heroBg} />
      <div className="contact-us-thanks">
        <img src={check} className="contact-us-thanks--check" alt="" />
        <Title level={2} className="text-center">
          Thank You!
        </Title>
        <p className="text-center">
          SpotOn team has already received your message successfully and will
          get back to you soon!
        </p>
        <CtaPrimary ctaTitle="Go back to home" target="/" />
      </div>
      <Row justify="center">
        <Col lg={12}>
          <div className="small-grid">
            <img className="small-grid-icon" src={knowledge} alt="" />
            <div>
              <h4>Knowledge Base</h4>
              <CtaSecondary ctaTitle="Support documentation" target="/" />
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="small-grid">
            <img className="small-grid-icon" src={team} alt="" />
            <div>
              <h4>Help Center</h4>
              <CtaSecondary ctaTitle="SpotOn help center" target="/" />
            </div>
          </div>
        </Col>
      </Row>

      <style jsx>{`
        .contact-us-thanks {
          padding: 193px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.07),
            20px 10px 40px rgba(47, 80, 167, 0.1);
          margin: -250px 20% 50px 20%;
          border-radius: 8px;
          background: ${colors.white};
        }
        .contact-us-thanks--check {
          width: 64px;
          display: table;
          margin: 32px auto;
        }
        .contact-us-thanks :global(.cta-primary) {
          display: flex;
          margin: 32px 25%;
        }

        @media ${mdOrSmaller} {
          .contact-us-thanks {
            padding: 100px;
          }

          .contact-us-thanks :global(.cta-primary) {
            margin: 32px 15%;
          }
        }

        @media ${smOrSmaller} {
          .contact-us-thanks {
            margin: -250px 32px 50px 32px;
          }

          .contact-us-thanks :global(.cta-primary) {
            margin: 32px 5%;
          }
        }
      `}</style>
    </Layout>
  );
}
